import Header from "@amzn/awsui-components-react/polaris/header";
import KeyValuePairs, { KeyValuePairsProps } from "@amzn/awsui-components-react/polaris/key-value-pairs";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import { Shift, Signup, Event, Question, Option } from "@amzn/red-velvet-api";
import { useTranslation } from "react-i18next";
import { useTimezone } from "../../hooks/timezone";
import { asDateString, asTimeString } from "../../utils/dateFormatting";
import { getShiftLengthLabel } from "../eventDisplay";
import { ShiftActions } from "./actions";

export interface ModalData {
  loading: boolean,
  waiverLinkOpened: boolean,
  formValidated: boolean,
  questionAnswers?: {
    question: Question,
    options: Option[];
    answer?: Option
  }[]
}

export function ShiftHeader({ shift }: { shift: Shift }) {
    return <Header variant="h3">{shift.name}</Header>
}

export function ShiftBody({ event, shift }: { event: Event, shift: Shift }) {
    const info: KeyValuePairsProps.Item[] = useShiftInfo({ event, shift });

    return (
        //THIS CLASS IS REQUIRED FOR INTEGRATION TESTING TO REGISTER THE EXISTANCE OF SHIFTS
        <div className="shift">
            <div className="shiftBody">
                {info.map(item => !item.type ? <p><b>{item.label}:</b> {item.value}</p> : null)}
            </div>
        </div>
    )
}

function useShiftInfo({ event, shift }: { event: Event, shift: Shift }): KeyValuePairsProps.Item[] {
    const { t, i18n } = useTranslation();
    const selectedTimezone = useTimezone();
    const hasLimitedSpots = shift.signupsRemaining && shift.maxSignup;

    const startDate = shift.startDateTime ? new Date(shift.startDateTime) : null;
    const endDate = shift.endDateTime ? new Date(shift.endDateTime) : null;
    
    const dateInfo: KeyValuePairsProps.Item[] = [];
    if(startDate && endDate) {
        const diffTime = endDate.getTime() - startDate.getTime();

        const timeString = asTimeString(startDate, selectedTimezone, i18n.language, {removeTimeZone:true});
        const localeStartDateString = asDateString(startDate, selectedTimezone, i18n.language);

        const eventLocalTimeStringNoTz = asTimeString(startDate, event.timeZone, i18n.language, {removeTimeZone:true});
        const eventLocalTimeString = asTimeString(startDate, event.timeZone, i18n.language, {removeTimeZone:true});
        const localTimeIdentical = eventLocalTimeStringNoTz === timeString;
        
        dateInfo.push(
            {
                label: t("eventDetails.shiftDisplay.date"),
                value: localeStartDateString
            },
            {
                label: t("eventDetails.shiftDisplay.startTime2"),
                value: <>
                    {timeString}
                    {localTimeIdentical ? null : 
                        <div className="shiftLocalDateTime"><small><b>{t("eventDetails.shiftDisplay.localStartTime")}:</b> {eventLocalTimeString}</small></div>}
                    </>
            },
            {
                label: t("eventDetails.shiftDisplay.length"),
                value: getShiftLengthLabel(t, diffTime)
            }
        );
    } else {
        dateInfo.push(
            {
                label: t("eventDetails.shiftDisplay.date"),
                value: t("eventDisplay.ongoingEvent")
            }
        );
    }
    
    const signupsRemaining: KeyValuePairsProps.Item[] = [
        {
            label: t("eventDetails.shiftDisplay.spotsAvailable"),
            value: hasLimitedSpots ? shift.signupsRemaining : t("eventDetails.shiftDisplay.unlimited")
        }
    ]
    if (hasLimitedSpots)  {
        signupsRemaining.unshift({
            label: t("eventDetails.shiftDisplay.signedUp"),
            value: (shift.maxSignup ?? 0) - (shift.signupsRemaining ?? 0)
        });
    }
        
    return [...dateInfo, ...signupsRemaining];
}

export function ShiftInfo({ event, shift }: { event: Event, shift: Shift }) {
    const { t } = useTranslation();
    const info = useShiftInfo({ event, shift });

    return (
        //THIS CLASS IS REQUIRED FOR INTEGRATION TESTING TO REGISTER THE EXISTENCE OF SHIFTS
        <div className="shift">
            <div className="shiftBody">
                <SpaceBetween direction="vertical" size="l">
                    <KeyValuePairs
                        columns={1}
                        items={[{
                            label: t("eventDetails.shiftDisplay.shiftName"),
                            value: shift.name
                        }]} />
                    <KeyValuePairs
                        columns={4}
                        items={info} />
                </SpaceBetween> 
            </div>
        </div>
    )
}

export type ShiftFooterProps = { 
    event: Event;
    shift: Shift;
    signup?: Signup;
};

export function ShiftFooter({ event, shift, signup}: ShiftFooterProps) {
    return (
        <div className="shiftFooter">
            <ShiftActions 
                event={event}
                shift={shift}
                signup={signup}/>
        </div>
    )
}
