export const externalLinks = {
  "utm": {
    "source": "VolunteerCenter",
    "campaign": "MLP"
  },
  "moreWays": {
    "planEvent": "https://w.amazon.com/bin/view/AmazonInTheCommunity/EE/Volunteering/PlanningaVolunteerEvent/",
    "plugAndPlay": "https://w.amazon.com/bin/view/AmazonInTheCommunity/EE/Volunteering/PlanningintheUS/#HPlugandPlay",
    "assistedPlanning": "https://w.amazon.com/bin/view/AmazonInTheCommunity/EE/Volunteering/PlanningintheUS/#HAssistedPlanning"
  },
  "support": {
    "documentation": "https://w.amazon.com/bin/view/AmazonInTheCommunity/Tech/VolunteerCenter/UserGuide/",
    "feedback": "https://survey.fieldsense.whs.amazon.dev/survey/37fe32f5-5841-4232-8d26-2cd497779a10",
    "feature": "https://sim.amazon.com/issues/create?template=4e30685d-c924-4864-b400-adfa8f86a9a1",
    "contact": "https://w.amazon.com/bin/view/AmazonInTheCommunity/EE/Contact/ContactUs/",
    "givehubTutorials": "https://w.amazon.com/bin/view/AmazonInTheCommunity/EE/GIVEhub/Tutorials/",
    "aciWiki": "https://w.amazon.com/bin/view/AmazonInTheCommunity/EE/",
    "vcWiki": "https://w.amazon.com/bin/view/AmazonInTheCommunity/EE/VolunteerCenter",
    "planningPlaybooks": "https://w.amazon.com/bin/view/AmazonInTheCommunity/EE/Volunteering/PlanningaVolunteerEvent/",
    "training": "https://knet.csod.com/ui/lms-learning-details/app/curriculum/31929310-981f-425f-8688-e1ea1b2ddf3a",
    "a2z": "https://news.a2z.com/channels/1303470",
    "hiring": "https://amazon.jobs/content/en/internal/teams/gcci/aci/?mons_redirect=sign_in&mons_redirect=sign_in#search",
    "trackTime": "https://amazon.benevity.org/volunteer/external/track"
  },
  "benevity": {
    "trackTime":"https://amazon.benevity.org/volunteer/external/track",
    "event": "https://amazon.benevity.org/volunteer/",
    "editEvent": "https://amazon.benevity.org/volunteer/{id}/edit",
    "manageSignups": "https://amazon.benevity.org/volunteer/{id}/manage/signups",
    "manageTime": "https://amazon.benevity.org/volunteer/{id}/manage/submissions",
    "bulkEmail": "https://amazon.benevity.org/adm/bulk_email/create?node_id={id}",
    "signup": "https://amazon.benevity.org/volunteer/signup/"
  },
  "bulkUpload": {
    "bulkUpdateInstructions": "https://w.amazon.com/bin/view/AmazonInTheCommunity/Tech/Hack-A-Thons/2023-Q4/BulkGIVEhubUpdates/#HUserGuide",
    "bulkUpdateTroubleshooting": "https://w.amazon.com/bin/view/AmazonInTheCommunity/Tech/Hack-A-Thons/2023-Q4/BulkGIVEhubUpdates/#HTroubleshooting",
    "accessRequest": "https://w.amazon.com/bin/view/AmazonInTheCommunity/Tech/Operations/TicketTemplates/#HBugReports"
  }
};
