import { Shift, Signup, Event, GetSignupsByQueryParamCommandOutput, GetShiftsByEventCommandOutput } from "@amzn/red-velvet-api";
import { getClient } from "../../utils/getClient";
import { QueryClient } from "@tanstack/react-query";
import { callQueryClientAllPagesRedVelvetQuery } from '../allPagesRedVelvetQuery'

type Status = {
  status:{ type: "success"}
} | {
  status:{ type: "error", reason:string}
}

type WithStatus<T> = {value?:T} & Status;
const asWithStatus = <T,>(promise:PromiseSettledResult<T>):WithStatus<T> => {
  return promise.status === "fulfilled" ? {
    status:{type:"success"},
    value:promise.value
  } : {
    status:{
      type:"error",
      reason:`${promise.reason}`
    }
  }
}

const loadAllShiftsForEvent = async (client:QueryClient, eventId:string):Promise<Shift[]>=>{
  return await callQueryClientAllPagesRedVelvetQuery(client, { 
    redVelvetQueryKey: ['getShiftsByEvent', { eventId }],
    converter: (output: GetShiftsByEventCommandOutput) => ({ items: output.shifts || [], next: output.page })
  });
};

const loadAllUsersignupsForEvent = async (client:QueryClient, eventId:string, alias:string):Promise<Signup[]> =>{
  return await callQueryClientAllPagesRedVelvetQuery(client, { 
    redVelvetQueryKey: ["getSignupsByQueryParam", { eventId, alias }],
    converter: (output: GetSignupsByQueryParamCommandOutput) => ({ items: output.signups || [], next: output.page })
  });
}


export type LoadedEvent = {
  eventId:string,
  event:WithStatus<Event>
  shifts:WithStatus<Shift[]>,
  signups:WithStatus<Signup[]>
}

/**
 * batch load all the events specified by id. Requires the logged in user to filter the signups.
 */
export async function loadBetterEvents(queryClient: QueryClient, ids: string[], userId: string):Promise<LoadedEvent[]>{
  const client = getClient();
  const events: WithStatus<Event>[] = (await Promise.allSettled(ids.map(eventId => client.getEventById({ eventId })))).map(asWithStatus);
  const shiftSets: WithStatus<Shift[]>[] = (await Promise.allSettled(ids.map(eventId => loadAllShiftsForEvent(queryClient, eventId)))).map(asWithStatus);
  const signupSets: WithStatus<Signup[]>[] = (await Promise.allSettled(ids.map(eventId => loadAllUsersignupsForEvent(queryClient, eventId, userId)))).map(asWithStatus);

  return ids.map((id, index)=> ({
    eventId:id,
    event:events[index],
    shifts:shiftSets[index],
    signups:signupSets[index]
  }));
}
