import i18n from "i18next"
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

import en from "./localization/en.json"; // default en json
// Individual files for specific components
import attendanceEn from "./components/attendance/localization/en.json";
import activityEn from "./components/myActivity/localization/en.json";

const translationResources = {
  en: {
    translation: en,
    attendance: attendanceEn,
    activity: activityEn
  }
}
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({ resources: translationResources, fallbackLng: "en", fallbackNS: 'translation'});

export default i18n
