// Components
import Alert from "@amzn/awsui-components-react/polaris/alert";
import Header from "@amzn/awsui-components-react/polaris/header";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import { ShiftActions } from "../shifts/actions";
import { ShiftInfo } from "../shifts/shift";
import { SpaLink } from "../SpaLink";
// Hooks
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSignupAlerts } from "../../hooks/shifts/shiftSignup";
// Types
import { Shift, Event, Signup } from "@amzn/red-velvet-api";

export function SignupHeader({ event, shift, signup }: { event: Event, shift: Shift, signup: Signup }) {
  const [searchParams] = useSearchParams();
  const selectedTimezone = searchParams.get("timezone");
  const urlFeatures = searchParams.get("features");
  const viewDetailsUrl = `/view-event?id=${event.eventId}${selectedTimezone ? '&timezone=' + selectedTimezone : ''}${urlFeatures ? '&features=' + urlFeatures : ''}`;
  
  // Note: this is a non-standard pattern; Cloudscape defaults to a hamburger menu in the corner of
  // the card for in-context action, but the design called for the buttons in order to enable one click actions
  return <Header
      actions={<ShiftActions event={event} shift={shift} signup={signup}/>}>
      <SpaLink
          data-external-analytics-on="click"
          data-external-analytics-name="searchResult_title"
          data-aci-analytics-name="searchResult_title"
          className={"card-title"}
          href={viewDetailsUrl}>
          <h2>{event.title}</h2>
      </SpaLink>
  </Header>
}

export function SignupCard({ event, shift, signup }: { event: Event, shift: Shift, signup: Signup }) {
  const { t } = useTranslation(["activity", "translation"]);

  // Note: The usage of alert within a card is non-standard for Cloudscape, but enables greater accessibility
  const alertInfo = useSignupAlerts(shift.shiftId);
  const alertContent = alertInfo.alert !== undefined ? <Alert
    dismissible
    statusIconAriaLabel={t("eventDisplay." + alertInfo.alert.type + "Title")}
    onDismiss={alertInfo.onDismiss}
    type={alertInfo.alert.type}
    header={t("eventDisplay." + alertInfo.alert.type + "Title")}>
    {alertInfo.alert.message}
  </Alert> : null;

  return <>
    <SpaceBetween direction="vertical" size="s">
      <ShiftInfo event={event} shift={shift} />
      {alertContent}
    </SpaceBetween>
  </>
}
